@import '../cartus-palette.scss';

.mat-dialog-container{
    position:relative !important;
    border-radius: 4px;
    padding: 1em !important;
    overflow:hidden !important;
    min-height: 450px;

    button[mat-dialog-close] {
        width: 24px;
        position: relative;
        left: 6px;
    }
    
    .mat-dialog-actions{
        button{
            width: 50%;
            &.contained-button{
                margin:0;
            }
            &.text-button{
                margin:0;
            }
        }
    }
    .mat-dialog-content{
        flex-grow: 1;
        
    }   
}

.mat-backdrop-transparent {
    background-color: transparent;
}
