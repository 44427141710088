.mat-expansion-panel {
    color: map-get($cartus-palette, 'cartus_gray');
}

.mat-expansion-panel-header {
    font-size: 1em !important; 
    font-weight: 100 !important;
    padding: 0 1em;
}

.mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background-color: inherit !important;
}
