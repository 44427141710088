@use '@angular/material' as mat;
@import '@angular/material/theming';

// Always include only once per project
@include mat.core();

// Import our custom theme
@import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import "~css-star-rating/css/star-rating.min.css";
@import 'sass/app.scss';
@import 'sass/common-styles.scss';


@mixin custom-components-theme($theme, $typography, $cartus-palette) {
    @include app($theme, $typography, $cartus-palette);
}

@include mat.all-component-themes($app-theme);

.ccu-theme {
    // use our theme with angular-material-theme mixin
    @include mat.all-component-themes($app-theme);
    // include custom components theme mixin once per theme class
    @include custom-components-theme($app-theme, $typography, $cartus-palette);
}
  
html, body {
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    /*height: 100vh;*/ min-height: 100%;
    min-width: 331px;
    overflow: visible !important;
    overscroll-behavior-y: none;
    font-family: 'Avenir-Book',sans-serif;
    font-size: 16px;
    color: map-get($cartus-palette, 'cartus_gray');
    -moz-osx-font-smoothing: grayscale; /* Fix blurry font for Firefox Mac users connected to external displays */
}

*[fxlayoutgap] > *:last-child {
    margin-right: 0; /* Fix for flex last column in a row having a right margin */
}

a {
    text-decoration: none;
}

iframe {
    border: 0;
}
app-root{
    width:100%;
}

.toasterMessage{
    font-family: 'Avenir-Book', sans-serif;
    color:  #EEECE9!important;
    font-size: 20px !important;
    .mat-simple-snackbar-action {
        color:  #EEECE9!important;
        font-size: 20px !important;
        font-weight: bolder;
        background-color: #3C72B9!important;
        border-radius: 5px!important;
    }
  }

  /** scrollbar customization **/

   /* width */
.mat-select-panel::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */

.mat-select-panel::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

.mat-select-panel::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 20px;
}

.custom-dialog {
  .scroll-div::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
}

/* Track */

.custom-dialog {
  .scroll-div::-webkit-scrollbar-track {
    background: transparent;
  }
}


/* Handle */
.custom-dialog {
  .scroll-div::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 20px;
  }
}
/** scrollbar customization **/

/** time picker */
.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.timepicker-style {
  .ngx-material-timepicker-toggle{
    width: 2em !important;
  }
}
/** Added to overcome icons cropped issue on angular upgrade*/
.mat-icon {
  overflow: visible !important;
}

.custom-html-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  z-index: 99999999;
  max-width: fit-content;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-family: sans-serif;
  font-size: 16px;
  bottom: 45px;
}

.p-b-10{
  padding-bottom: 10px;
}

.center {
    text-align: center;
}