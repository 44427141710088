.mat-chip{
    &.mat-standard-chip{
        background: map-get($cartus-palette, 'cartus_gray');
        color: #ffffff;
        .mat-chip-remove{
            color: #ffffff;
            opacity: 1;
        }
    }
}
