.mat-paginator{
    background: transparent;
    .mat-paginator-container{
        position: relative;
        .mat-paginator-page-size{
          position:absolute;
          left:0;
        }
        .mat-paginator-range-actions{
          position:relative;
          padding-left:40px;
          .mat-paginator-range-label{
              margin:0;
          }
          .mat-paginator-navigation-previous{
            position:absolute;
            left:0
          }
        }
    }
}
