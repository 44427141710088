@import '../sass/cartus-palette.scss';
@import './material/button.scss';
@import './material/mat-chip.scss';
@import './material/mat-dialog.scss';
@import './material/mat-expansion-panel.scss';
@import './material/mat-paginator.scss';
@import './material/mat-table.scss';
@import './material/mat-card.scss';
@import './material/mat-divider.scss';
@import './material/mat-checkbox.scss';

h1{
    font-size: 1.5em;
    font-family: "Avenir-Book", sans-serif;
    padding-right: 2.5em;
}

h2 {
    font-size: 1.25em;
    font-family: "Avenir-Medium", sans-serif;
}

.small-text {
  font-size: 0.75em;
  font-family: 'Avenir-Book',sans-serif;
  color: map-get($cartus-palette, 'cartus_charcoal_gray');
  text-align: left;
  line-height: 1em;
}

.body-copy {
  font-family: "Avenir-Book", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  text-align: left;
  color: map-get($cartus-palette, 'cartus_charcoal_gray');
}

a {
  color: map-get($cartus-palette, 'cartus_accent_blue');
  font-size: 1em;
  font-family: 'Avenir-Book', sans-serif;
  cursor: pointer;
  &.small-text,
  .small-text {
    color: map-get($cartus-palette, 'cartus_accent_blue');
    text-align: unset;
    line-height: unset
  }
  
  &:hover {
    color: map-get($cartus-palette, 'cartus_dark_accent_blue');
    background-color: map-get($cartus-palette, 'cartus_hover_tint');
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
}

.icon-text-link {
  cursor: pointer;
    i,
      mat-icon {
          margin-left: 4.25px;
          color: map-get($cartus-palette, 'cartus_medium_gray');
      }
  &:hover {
      color: map-get($cartus-palette, 'cartus_dark_accent_blue');
      background-color: map-get($cartus-palette, 'cartus_hover_tint');
  }
  &:focus {
    outline: unset;
  }
}

/*menu styles */
.menu-style[aria-expanded="true"] {
    .menuName {
        text-decoration: underline;  
        span {
            color:map-get($cartus-palette, 'cartus_accent_blue') !important;
        }
    }
    .menuIconBlock {
        .expandMore {
            display:none;
        }
        .expandLess {
            display:block;
        }
        .mat-icon {
            color:map-get($cartus-palette, 'cartus_accent_blue') !important;
        }
    }
}

.menu-style {
    font-family: 'Avenir-Book', sans-serif;
    color: map-get($cartus-palette, 'cartus_medium_gray');
    &:hover {
        .menuName {
            text-decoration: underline;
        }
        background-color:map-get($cartus-palette, 'cartus_hover_tint');
    }
    &:active {
        .menuName {
            text-decoration: underline;
        }
        color:map-get($cartus-palette, 'cartus_dark_accent_blue');
    }
    .menuIconBlock {
        .mat-icon {
            font-weight: bold;
            font-size: 1.75em;
        }
        .expandMore {
            display: block;
        }
        .expandLess {
            display: none;
        }
    }
}

.mat-menu-panel {
    .mat-menu-content {
        padding: 0;
        button.mat-menu-item {
            font-family: 'Avenir-Book', sans-serif;
            /* Was Avenir-Roman, temp fix for menu item latency issue */
            &:hover {
                color: map-get($cartus-palette, 'cartus_dark_accent_blue');
                background-color: map-get($cartus-palette, 'cartus_orange_tint');
                .mat-icon {
                    color: map-get($cartus-palette, 'cartus_dark_accent_blue');
                }
            }
        }
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline {
        color: map-get($cartus-palette, 'cartus_medium_gray');
        background: #ffffff;
        .mat-form-field-outline-start {
            border-radius: 0;
        }
        .mat-form-field-outline-end {
            border-radius: 0;
        }
    }
    .mat-input-element {
        font-family: 'Avenir-Book', sans-serif;
        font-weight: normal;
        font-size: 1em;
        color: map-get($cartus-palette, 'cartrus_gray')
    }
}

.mat-select-panel {
    .mat-option {
        &:hover {
            background-color: map-get($cartus-palette, 'cartus_orange_tint');
        }
    }
}

.full-width {
    width: 100%;
}

.align-right {
    text-align: left;
}

.amount-format {
    span {
        float: right;
        white-space: nowrap;
    }
}


/*- Sentiment styles -*/
.rating {
    cursor: pointer;
    align-items: flex-start;
    justify-content: flex-start;
    &.color-negative,
    &.color-ok,
    &.color-positive,
    &.color-default {
        .star-container {
            margin-left: 0;
            .star {
                .star-filled {
                    fill: map-get($cartus-palette, 'cartus_dark_accent_blue') !important;
                }
                .star-empty {
                    fill: map-get($cartus-palette, 'cartus_disabled_gray') !important;
                }
                i {
                    color: map-get($cartus-palette, 'cartus_disabled_gray') !important;
                }
            }
        }
    }
}

.highlight-search {
    background-color: map-get($cartus-palette, 'cartus_highlight');
}


/*- Force toast errors to display dimiss icon on right -*/

.toast-error {
    background-color: map-get($cartus-palette, 'cartus_gray') !important;
    background-position: 96% !important;
    padding-left: 15px !important;
    width: 21.5em !important;
}


/* styling for report generate/download Toastr */

#toast-container {
    .toast-info {
        background-color: map-get($cartus-palette, 'cartus_gray') !important;
    }
    .toast-close-button {
        font-weight: normal !important;
        top: 0em !important;
        &:hover {
            color: #FFFFFF !important;
            opacity: 1 !important;
        }
    }
}

.custom-toast{
    width: fit-content!important;
  }

.custom-toast .toast-message {
    padding-right: 50px !important;
}