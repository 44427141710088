@import '../cartus-palette.scss';

.mat-checkbox {
  &.mat-checkbox-checked {
    .mat-checkbox-background {
      border-radius: 0.313em;
      background-color: map-get($cartus-palette, 'cartus_link_color');
      border: 0.063em map-get($cartus-palette, 'cartus_link_color') !important;
    }
  }

  &:disabled {
    color: map-get($cartus-palette, 'cartus_medium_gray') !important;
  }

  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      margin-top: 0.2em !important;
      margin-right: 0.625em;
    }

    .mat-checkbox-frame,
    .mat-checkbox-background {
      border: 0.063em solid map-get($cartus-palette, 'cartus_secondary_gray');
      border-radius: 0.313em;
      width: 1.09em;
      height: 1.09em;

      .mat-checkbox-checkmark {
        width: 0.875em;
        height: 0.875em;
        top: 0.013em;
      }
    }
  }
}
