@import '../cartus-palette.scss';

/* button styles */

button {
    &.contained-button {
        font-family: 'Avenir-Light', sans-serif;
        color: map-get($cartus-palette, 'cartus_nav_tile');
        font-size: 1em;
        height: 3em;
        border-radius: 4px;
        padding: 0.5em;
        background-color: map-get($cartus-palette, 'cartus_accent_blue');
        border: none;
        min-width: 6.25em;
        line-height: 1.5em;
        &:active {
            text-decoration: underline;
            border: 2px solid map-get($cartus-palette, 'cartus_dark_accent_blue');
            background-color: map-get($cartus-palette, 'cartus_accent_blue');
        }
        &:focus {
            text-decoration: underline;
            border: 2px solid map-get($cartus-palette, 'cartus_dark_accent_blue');
            background-color: map-get($cartus-palette, 'cartus_accent_blue');
        }
        &:hover {
            background-color: map-get($cartus-palette, 'cartus_dark_accent_blue');
        }
        &:disabled {
            color: map-get($cartus-palette, 'cartus_gray');
            background-color: map-get($cartus-palette, 'cartus_disabled_gray');
        }
    }
    &.outlined-button {
        font-family: 'Avenir-Light', sans-serif;
        color: map-get($cartus-palette, 'cartus_dark_accent_blue');
        font-size: 1em;
        height: 3em;
        line-height: 1.5em;
        border-radius: 0.25em;
        padding: 0.5em;
        border: 1px solid map-get($cartus-palette, 'cartus_medium_gray');
        min-width: 6.25em;
        &:active {
            text-decoration: underline;
            color: map-get($cartus-palette, 'cartus_dark_accent_blue');
            border: 1px solid map-get($cartus-palette, 'cartus_medium_gray');
            background-color: map-get($cartus-palette, 'cartus_hover_tint');
        }
        &:focus {
            text-decoration: underline;
            color: map-get($cartus-palette, 'cartus_dark_accent_blue');
            border: 1px solid map-get($cartus-palette, 'cartus_medium_gray');
            background-color: map-get($cartus-palette, 'cartus_hover_tint');
        }
        &:hover {
            color: map-get($cartus-palette, 'cartus_dark_accent_blue');
            border: 1px solid map-get($cartus-palette, 'cartus_medium_gray');
        }
        &:disabled {
            color: map-get($cartus-palette, 'cartus_gray');
            border: 1px solid map-get($cartus-palette, 'cartus_medium_gray');
        }
    }
    &.text-button {
        font-family: 'Avenir-Light', sans-serif;
        color: map-get($cartus-palette, 'cartus_accent_blue');
        font-size: 1em;
        height: 3em;
        border-radius: 4px;
        padding: 0.5em;
        min-width: 6.25em;
        line-height: 1.5em;
        &:focus {
            text-decoration: underline;
            border: 2px solid map-get($cartus-palette, 'cartus_dark_accent_blue');
            color: map-get($cartus-palette, 'cartus_dark_accent_blue');
        }
        &:active {
            text-decoration: underline;
            border: 2px solid map-get($cartus-palette, 'cartus_dark_accent_blue');
            color: map-get($cartus-palette, 'cartus_dark_accent_blue');
        }
        &:hover {
            color: map-get($cartus-palette, 'cartus_dark_accent_blue');
            background-color: map-get($cartus-palette, 'cartus_hover_tint');
        }
        &:disabled {
            color: map-get($cartus-palette, 'cartus_gray');
            background-color: map-get($cartus-palette, 'cartus_disabled_gray');
        }
    }
}

button:not(:last-of-type) {
    /* Add spacing for adjacent buttons so they are not flush */
    margin-right: .75em;
}